import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api, { ApiWithFile } from "../../Api/ApiConfig";


// get all Category
export const getAllCategory = createAsyncThunk(
    "category/getAllCategory",
    async ({ page = 1, searchQuery='' }, { rejectWithValue }) => {
        try {
            const responce = await api.get(`/api/category/getCategory?page=${page}&per_page=10&search=${searchQuery}`);
            return responce?.data;
        } catch (error) {
            return rejectWithValue(error.responce?.data);
        }
    }
);


// Create Category
export const createCategory = createAsyncThunk(
    "category/create",
    async (credentials, thunkAPI) => {
        try {
            const responce = await ApiWithFile.post(`/api/category/createCategory`, credentials);
            return responce?.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

// delete Category
export const deleteCategory = createAsyncThunk(
    'category/delete',
    async (categoryId, thunkAPI) => {
        try {
            await api.delete(`/api/category/deleteCategory/${categoryId}`);
            return categoryId;
        } catch (error) {
            return thunkAPI.rejectWithValue(error?.responce?.data);
        }
    }
);



const initialState = {
    categories: [],
    loading: false,
    error: false,
    page: 1,
    totalPages: 1,
}
export const categorySlice = createSlice({
    name: "category",
    initialState,
    reducers: {
        onPageChange: (state, action) => {
            state.page = action.payload;
        }
    },

    extraReducers: (builder) => {

        // create a subject
        builder
            .addCase(createCategory.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(createCategory.fulfilled, (state, action) => {
                state.loading = false;
                state.categories.unshift(action?.payload?.data);
            })
            .addCase(createCategory.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            // get all subjects
            .addCase(getAllCategory.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getAllCategory.fulfilled, (state, action) => {
                state.loading = false;
                state.categories = action.payload?.data;
                state.page = action.payload?.pagination?.currentPage;
                state.totalPages = action.payload?.pagination?.totalPages;
            })
            .addCase(getAllCategory.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            // delete a subject
            .addCase(deleteCategory.fulfilled, (state, action) => {
                state.loading = false;
                state.categories = state.categories.filter(item => item._id !== action.payload);
            })
    }
});

export const { onPageChange } = categorySlice.actions;
export default categorySlice.reducer;