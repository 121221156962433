import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../Api/ApiConfig";

const userId = localStorage.getItem('userId');

export const getUserById = createAsyncThunk(
    "user/getById",
    async (_, thunkAPI) => {
        try {
            const res = await api.get(`api/user/getUserById/${userId}`);
            return res?.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error?.responce?.data);
        }
    }
);


const initialState = {
    user:null,
    enrolledCourses: [],
    loading: false,
    error: null,
}
const userSlice = createSlice({
    name: "user",
    initialState: initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(getUserById.pending,(state)=>{
                state.loading=false;
                state.error=null;
            })
            .addCase(getUserById.fulfilled,(state,action)=>{
                state.loading=false;
                state.user=action?.payload?.data;
                // state?.enrolledCourses=action?.payload?.enrolledCourses
            })
            .addCase(getUserById.rejected,(state,action)=>{
                state.loading=false;
                state.error=action?.payload
            })
    }

});

export default userSlice.reducer;