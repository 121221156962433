// src/components/FullScreenLoader.js
import React, { useEffect, useState } from "react";

const InitialLoader = ({ message = "Loading...",delay=1000 }) => {
    // const [showLoader, setShowLoader] = useState(false);

    // useEffect(() => {
    //   const timer = setTimeout(() => setShowLoader(true), delay);
    //   return () => clearTimeout(timer);
    // }, [delay]);
  
    // if (!showLoader) return null;
  return (
    <div className="fixed inset-0 bg-blue-100 flex flex-col justify-center items-center z-50">
      <div className="w-16 h-16 border-4 border-blue-300 border-t-blue-500 rounded-full animate-spin"></div>
      <p className="mt-4 text-blue-600 text-lg font-semibold">{message}</p>
    </div>
  );
};

export default InitialLoader;
