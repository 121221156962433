import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../Api/ApiConfig";


const userId = localStorage.getItem('userId');
console.log(userId);

// get all course
export const getAllCourse = createAsyncThunk(
    "course/get",
    async (_, thunkAPI) => {
        try {
            const res = await api.get(`/api/liveclass/getAllLiveClass`);
            return res?.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error?.responce?.data);
        }
    }
);
export const getCourseById = createAsyncThunk(
    "course/getById",
    async (id, thunkAPI) => {
        try {
            const res = await api.get(`/api/liveclass/getLiveCourseById/${id}`);
            return res?.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error?.responce?.data);
        }
    }
);

export const enrollInCourse = createAsyncThunk(
    "course/enroll",
    async (courseId, { rejectWithValue }) => {
        try {
            const res = await api.patch(`/api/liveclass/enroll/${courseId}`, { userId });
            return res?.data;
        } catch (error) {
            return rejectWithValue(error?.responce?.data);
        }
    }
);
// export const getScheduledClassByClassId=createAsyncThunk(
//     "scheduleClass/get",
//     async(classId,{rejectWithValue})=>{
//         try {
//             const res=await api?.get(`/`)
//         } catch (error) {

//         }
//     }
// )

const initialState = {
    courses: [],
    loading: false,
    error: null,
    totalpage: null,
    currentPage: null,
}
const liveCourseSlice = createSlice({
    name: "liveCourse",
    initialState: initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllCourse.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getAllCourse.fulfilled, (state, action) => {
                state.loading = false;
                state.courses = action.payload?.classes;
                // state.totalPages = action.payload.pagination?.totalPages;
                // state.page = action.payload.pagination?.currentPage;
            })
            .addCase(getAllCourse.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            // get course by id
            .addCase(getCourseById.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getCourseById.fulfilled, (state, action) => {
                state.loading = false;
                state.courses = action.payload?.course;
            })
            .addCase(getCourseById.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            // course enroll
            .addCase(enrollInCourse.pending,(state)=>{
                state.loading=false;
                state.error=null;
            })
            .addCase(enrollInCourse.fulfilled,(state,action)=>{
                console.log(action);
                
                state.loading=false;
                state.courses=action?.payload
            })
            .addCase(enrollInCourse.rejected,(state,action)=>{
                state.loading=false;
                state.error=action?.payload
            })
    }

});

export default liveCourseSlice.reducer;