import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import api, { apiWithOutAuth } from "../../Api/ApiConfig";

// Async action to login the user
export const login = createAsyncThunk(
  "/auth/login",
  async (credentials, thunkAPI) => {
    try {
      // const response = await axios.post("http://localhost:5000/api/auth/login", credentials);
      const response = await apiWithOutAuth.post("/api/auth/login", credentials);
      return response.data;
    } catch (error) {
      console.log("Error from login",error);
      
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const logout = createAsyncThunk(
  "/auth/logout",
  async () => {
    const response = await apiWithOutAuth.post(
      "/api/auth/logout",
      {},
      {
        withCredentials:true
      }
    );
    localStorage.removeItem("Admin_token")
    localStorage.removeItem("userId")
    return response.data;
  }
);
// check auth
export const checkAuth = createAsyncThunk(
  "/auth/checkauth",

  async () => {
    const response = await api.get(
      // "https://examatlas-backend.onrender.com/api/auth/check-auth",
      "/api/auth/check-auth",
      {
        withCredentials: true,
        headers: {
          "Cache-Control":
            "no-store, no-cache, must-revalidate, proxy-revalidate",
        },
      }
    );

    return response.data;
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: null,
    isAuthenticated: false,
    isAdmin: false,
    loading: false,
    error: null,
  },
  reducers: {
    // logout: (state) => {
    //   state.user = null;
    //   state.token = null;
    //   localStorage.removeItem("Admin_token");
    // },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.isAuthenticated = false;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload?.status ? action.payload.data : null;
        state.isAuthenticated = true;
        localStorage.setItem("Admin_token",action?.payload?.token);
        localStorage.setItem("userId",action?.payload?.userId);
        state.isAdmin = action.payload.data?.role === "admin" ? true : false;
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.isAuthenticated=false;
      })
      // check auth
      .addCase(checkAuth.pending, (state) => {
        state.loading = true;
      })
      .addCase(checkAuth.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload.success ? action.payload.user : null;
        state.isAuthenticated = true;
        state.isAdmin=action.payload.user?.role === "admin" ? true : false;
      })
      .addCase(checkAuth.rejected, (state, action) => {
        state.loading = false;
        state.user = null;
        state.isAuthenticated = false;
      })
      // logout
      .addCase(logout.fulfilled, (state, action) => {
        state.loading = false;
        localStorage.removeItem("Admin_token")
        localStorage.removeItem("userId")
        state.user = null;
        state.isAuthenticated = false;
        state.isAdmin=false;
      });
  },
});

// export const { logout } = authSlice.actions;

export default authSlice.reducer;
