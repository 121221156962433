import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../Api/ApiConfig";


// get all subjects
export const getAllSubCategory = createAsyncThunk(
    "subCategory/getAllSubCategory",
    async ({ page = 1, searchQuery='',categoryid='' }, { rejectWithValue }) => {
        try {
            const responce = await api.get(`/api/category/getSubCategory?page=${page}&per_page=10&search=${searchQuery}&categoryId=${categoryid}`);
            return responce?.data;
        } catch (error) {
            return rejectWithValue(error.responce?.data);
        }
    }
);

// get subject by id

// Add subjects
export const createSubCategory = createAsyncThunk(
    "subCategory/create",
    async (credentials, thunkAPI) => {
        try {
            const responce = await api.post(`/api/category/createSubCategory`, credentials);
            return responce?.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

// delete subjects
export const deleteSubCategory = createAsyncThunk(
    'subCategory/delete',
    async (subCategoryId, thunkAPI) => {
        try {
            await api.delete(`/api/category/deleteSubCategory/${subCategoryId}`);
            return subCategoryId;
        } catch (error) {
            return thunkAPI.rejectWithValue(error?.responce?.data);
        }
    }
);



const initialState = {
    subCategories: [],
    loading: false,
    error: false,
    page: 1,
    totalPages: 1,
}
export const subCategorySlice = createSlice({
    name: "subCategory",
    initialState,
    reducers: {
        onPageChange: (state, action) => {
            state.page = action.payload;
        }
    },

    extraReducers: (builder) => {

        // create a subject
        builder
            .addCase(createSubCategory.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(createSubCategory.fulfilled, (state, action) => {
                state.loading = false;
                state.subCategories.unshift(action?.payload?.data);
            })
            .addCase(createSubCategory.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            // get all subjects
            .addCase(getAllSubCategory.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getAllSubCategory.fulfilled, (state, action) => {
                state.loading = false;
                state.subCategories = action.payload?.data;
                state.page = action.payload?.pagination?.currentPage;
                state.totalPages = action.payload?.pagination?.totalPages;
            })
            .addCase(getAllSubCategory.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            // delete a subject
            .addCase(deleteSubCategory.fulfilled, (state, action) => {
                state.loading = false;
                state.subCategories = state.subCategories.filter(item => item._id !== action.payload);
            })
    }
});

export const { onPageChange } = subCategorySlice.actions;
export default subCategorySlice.reducer;