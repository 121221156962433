import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api, { ApiWithFile } from "../Api/ApiConfig";

// Add Blogs
export const createCurrentAffairs = createAsyncThunk(
    "ca/create",
    async (credentials, thunkAPI) => {
        try {
            const responce = await ApiWithFile.post(`/api/currentAffair/createCA`, credentials);
            return responce?.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

// Get All Blogs
export const getAllCurrentAffairs = createAsyncThunk(
    "ca/getAllCurrentAffairs",
    async ({page,searchQuery}, thunkAPI) => {
        try {
            const responce = await api.get(`/api/currentAffair/getAllCA?page=${page}&per_page=10&search=${searchQuery}`);
            return responce?.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error?.responce?.data);
        }
    }
);
// get current Affairs by id
export const getCurrentAffairsById = createAsyncThunk(
    "ca/getCurrentAffairsById",
    async (currentAffairId, thunkAPI) => {
        try {
            const responce = await api.get(`/api/currentAffair/getById/${currentAffairId}`);
            return responce?.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error?.responce?.data);
        }
    }
);
// delete current affairs
export const deleteCurrentAffairs = createAsyncThunk(
    'ca/delete',
    async (currentAffairId, thunkAPI) => {
        try {
            await api.delete(`/api/currentAffair/deleteById/${currentAffairId}`);
            return currentAffairId;
        } catch (error) {
            return thunkAPI.rejectWithValue(error?.responce?.data);
        }
    }
);
// edit current affairs
export const editCurrentAffairs=createAsyncThunk(
    'ca/update',
    async({currentAffairId,updatedData},thunkAPI)=>{
        try {
             const responce=await api.put(`/api/currentAffair/updateCA/${currentAffairId}`,updatedData);
             return responce?.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error?.responce?.data);
        }
    }
)


export const currentAffairsSlice = createSlice({
    name: "currentAffairs",
    initialState: {
        currentAffairs: [],
        totalPages: 1,
        page: 1,
        loading: false,
        error: null,
    },
    reducers: {
        onPageChange: (state, action) => {
            state.page = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(createCurrentAffairs.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(createCurrentAffairs.fulfilled, (state, action) => {
                state.loading = false;
                state.currentAffairs = action.payload;
            })
            .addCase(createCurrentAffairs.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            // get all Blogs
            .addCase(getAllCurrentAffairs.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getAllCurrentAffairs.fulfilled, (state, action) => {
                state.loading = false;
                state.currentAffairs = action.payload?.data;
                state.totalPages = action.payload.pagination?.totalPages;
                state.page = action.payload.pagination?.currentPage;
            })
            .addCase(getAllCurrentAffairs.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            // delete currrent affairs
            .addCase(deleteCurrentAffairs.fulfilled, (state, action) => {
                state.loading=false;
                state.currentAffairs = state.currentAffairs.filter(item => item._id !== action.payload);
            })
            // get current affirs by id
            .addCase(getCurrentAffairsById.pending,(state)=>{
                state.loading=false;
                state.error=null;
            })
            .addCase(getCurrentAffairsById?.fulfilled,(state,action)=>{
                state.loading=false;
                state.currentAffairs=action?.payload?.currentAffair;
                state.error=null;
            }) 
            .addCase(getCurrentAffairsById?.rejected,(state,action)=>{
                state.loading=false;
                state.error=action.payload;
            }) 
            // Update current affairs
            .addCase(editCurrentAffairs.pending,(state)=>{
                state.loading=true;
                state.error=null;
            }) 
            .addCase(editCurrentAffairs.fulfilled,(state,action)=>{
                state.loading=false;
                state.currentAffairs=action.payload
                state.error=null;
            }) 
            .addCase(editCurrentAffairs.rejected,(state,action)=>{
                state.loading=false;
                state.error=action.payload;
            }) 
            
    },
});
export const { onPageChange } = currentAffairsSlice.actions;
export default currentAffairsSlice.reducer;
