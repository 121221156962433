import { configureStore } from '@reduxjs/toolkit'
// import authReducer  from '../Admin/features/slices/authSlice';
import blogReducer from './blogSlice';
import currentAffairsReducers from './currentAffairsSlices';
import subjectReducer from './master/categorySlices';
import categoryReducer from './master/categorySlices';
import subCategoryReducer from './master/subCategorySlices';
import courseReducer from './courses/coursesSlices';
import LiveClassReducer from './liveClasses/liveclassSlice';
import scheduleLiveCoursesReducer from './liveClasses/ScheduleLiveClassSlice';
import bookReducer from './books/bookSlice';
import authReducer from './auth/authSlice';

// user side Reducer
import liveCourseReducer from './live-course/liveCourseSlice';
import userReducer from './user/userSlice';

export const store = configureStore({
  reducer: {
    auth:authReducer,
    blogs:blogReducer,
    currentAffairs:currentAffairsReducers,
    subjects:subjectReducer,
    category:categoryReducer,
    subcategory:subCategoryReducer,
    course:courseReducer,
    liveClass:LiveClassReducer,
    scheduleLiveCourses:scheduleLiveCoursesReducer,
    book:bookReducer,

    // user side reducer
    liveCourse:liveCourseReducer,
    user:userReducer,
  },
});