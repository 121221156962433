import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api, { ApiWithFile } from '../Api/ApiConfig';

// Add Blogs
export const createBlog = createAsyncThunk(
    "blog/create",
    async (credentials, thunkAPI) => {
        try {
            const responce = await ApiWithFile.post(`/api/blog/createBlog`, credentials);
            return responce?.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

// Get All Blogs
export const getAllBlogs = createAsyncThunk(
    "blog/getAllBlog",
    async ({page=1,searchQuery}, thunkAPI) => {
        try {
            const responce = await api.get(`/api/blog/getAllBlogs?page=${page}&per_page=10&search=${searchQuery}`);
            return responce?.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error?.responce?.data);
        }
    }
);
// get blog by id
export const getBlogById = createAsyncThunk(
    "blog/getBlogById",
    async (blogId, thunkAPI) => {
        try {
            const responce = await api.get(`/api/blog/getBlogById/${blogId}`);
            return responce?.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error?.responce?.data);
        }
    }
);
// delete blog
export const deleteBlog = createAsyncThunk(
    'blog/delete',
    async (blogId, thunkAPI) => {
        try {
            await api.delete(`/api/blog/deleteBlog/${blogId}`);
            return blogId;
        } catch (error) {
            return thunkAPI.rejectWithValue(error?.responce?.data);
        }
    }
);
// edit blog
export const editBlog=createAsyncThunk(
    'blog/update',
    async({blogId,updatedData},thunkAPI)=>{
        try {
             const responce=await api.put(`/api/blog/updateBlog/${blogId}`,updatedData);
             return responce?.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error?.responce?.data);
        }
    }
)

export const blogSlice = createSlice({
    name: "blog",
    initialState: {
        blogs: [],
        totalPages: 1,
        page: 1,
        loading: false,
        error: null,
    },
    reducers: {
        onPageChange: (state, action) => {
            state.page = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(createBlog.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(createBlog.fulfilled, (state, action) => {
                state.loading = false;
                state.blogs = action.payload;
            })
            .addCase(createBlog.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            // get all Blogs
            .addCase(getAllBlogs.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getAllBlogs.fulfilled, (state, action) => {
                state.loading = false;
                state.blogs = action.payload?.data;
                state.totalPages = action.payload.pagination?.totalPages;
                state.page = action.payload.pagination?.currentPage;
            })
            .addCase(getAllBlogs.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            // delete blog
            .addCase(deleteBlog.fulfilled, (state, action) => {
                state.loading=false;
                state.blogs = state.blogs.filter(item => item._id !== action.payload);
            })
            // get blog by id 
            .addCase(getBlogById?.pending,(state)=>{
                state.loading=true;
                state.error=null;
            }) 
            .addCase(getBlogById?.fulfilled,(state,action)=>{
                state.loading=false;
                state.blogs=action?.payload?.blog;
                state.error=null;
            }) 
            .addCase(getBlogById?.rejected,(state,action)=>{
                state.loading=false;
                state.error=action.payload;
            }) 
            // Update blog
            .addCase(editBlog.pending,(state)=>{
                state.loading=true;
                state.error=null;
            }) 
            .addCase(editBlog.fulfilled,(state,action)=>{
                state.loading=false;
                state.blogs=action.payload
                state.error=null;
            }) 
            .addCase(editBlog.rejected,(state,action)=>{
                state.loading=false;
                state.error=action.payload;
            }) 
            
    },
});
export const { onPageChange } = blogSlice.actions;
export default blogSlice.reducer;
