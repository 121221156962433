import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../Api/ApiConfig";

// Add Blogs
export const createCourse = createAsyncThunk(
    "course/create",
    async (credentials, thunkAPI) => {
        try {
            const responce = await api.post(`api/course/createCourse`, credentials);
            return responce?.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

// Get All Blogs
export const getAllCourse = createAsyncThunk(
    "course/getAllCourse",
    async ( thunkAPI) => {
        try {
            const responce = await api.get(`/api/course/getAllCourse`);
            return responce?.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error?.responce?.data);
        }
    }
);
// get blog by id
export const getCourseById = createAsyncThunk(
    "course/getCourseById",
    async (blogId, thunkAPI) => {
        try {
            const responce = await api.get(`/api/blog/getBlogById/${blogId}`);
            return responce?.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error?.responce?.data);
        }
    }
);
// delete blog
export const deleteCourse = createAsyncThunk(
    'course/delete',
    async (blogId, thunkAPI) => {
        try {
            await api.delete(`/api/blog/deleteBlog/${blogId}`);
            return blogId;
        } catch (error) {
            return thunkAPI.rejectWithValue(error?.responce?.data);
        }
    }
);
// edit blog
export const editCourse=createAsyncThunk(
    'course/update',
    async({blogId,updatedData},thunkAPI)=>{
        try {
             const responce=await api.put(`/api/blog/updateBlog/${blogId}`,updatedData);
             return responce?.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error?.responce?.data);
        }
    }
)

export const courseSlice = createSlice({
    name: "course",
    initialState: {
        courses: [],
        totalPages: 1,
        page: 1,
        loading: false,
        error: null,
    },
    reducers: {
        onPageChange: (state, action) => {
            state.page = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(createCourse.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(createCourse.fulfilled, (state, action) => {
                state.loading = false;
                state.courses = action.payload;
            })
            .addCase(createCourse.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            // get all Course
            .addCase(getAllCourse.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getAllCourse.fulfilled, (state, action) => {
                state.loading = false;
                state.courses = action.payload?.course;
                // state.totalPages = action.payload.pagination?.totalPages;
                // state.page = action.payload.pagination?.currentPage;
            })
            .addCase(getAllCourse.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            // delete blog
            .addCase(deleteCourse.fulfilled, (state, action) => {
                state.loading=false;
                state.courses = state.courses.filter(item => item._id !== action.payload);
            })
            // get blog by id 
            .addCase(getCourseById?.pending,(state)=>{
                state.loading=true;
                state.error=null;
            }) 
            .addCase(getCourseById?.fulfilled,(state,action)=>{
                state.loading=false;
                state.courses=action?.payload?.blog;
                state.error=null;
            }) 
            .addCase(getCourseById?.rejected,(state,action)=>{
                state.loading=false;
                state.error=action.payload;
            }) 
            // Update blog
            .addCase(editCourse.pending,(state)=>{
                state.loading=true;
                state.error=null;
            }) 
            .addCase(editCourse.fulfilled,(state,action)=>{
                state.loading=false;
                state.courses=action.payload
                state.error=null;
            }) 
            .addCase(editCourse.rejected,(state,action)=>{
                state.loading=false;
                state.error=action.payload;
            }) 
            
    },
});
export const { onPageChange } = courseSlice.actions;
export default courseSlice.reducer;
