import axios from 'axios';

// const baseURL =  'https://examatlas-backend.onrender.com';
const baseURL = process.env.REACT_APP_RUNNING_MODE === 'development'
  ? 'http://localhost:5000'
  : 'https://examatlas-backend.onrender.com';

const token=localStorage.getItem("Admin_token");
console.log(token);


const api = await axios.create({
  baseURL: baseURL,
  withCredentials: true,
  headers: {
    'Authorization': `Bearer ${localStorage.getItem("Admin_token")}`,
  }
});

export const ApiWithFile = await axios.create({
  baseURL:baseURL,
  withCredentials: true,
  headers: {
    'Content-Type': 'multipart/form-data',
    'Authorization': `Bearer ${token}`,
  }
});

export const apiWithOutAuth = axios.create({
  baseURL:baseURL,
  withCredentials: true,
  // headers:{
  //     // "Content-Type":""
  // }
})

export default api;



