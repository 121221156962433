import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api, { ApiWithFile } from "../../Api/ApiConfig";


// Add Book
export const createBook = createAsyncThunk(
    "book/create",
    async (credentials, thunkAPI) => {
        try {
            const responce = await ApiWithFile.post(`/api/book/createBook`, credentials);
            return responce?.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

// Get All Book
export const getAllBook = createAsyncThunk(
    "book/getAllBook",
    async ({page=1,searchQuery=''}, thunkAPI) => {
        try {
            const responce = await api.get(`/api/book/getAllBooks?page=${page}&per_page=10&search=${searchQuery}`);
            // const responce = await api.get(`/api/book/getAllBooks`);
            return responce?.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error?.responce?.data);
        }
    }
);
// get Book by id
export const getBookById = createAsyncThunk(
    "book/getBookById",
    async (BookId, thunkAPI) => {
        try {
            const responce = await api.get(`/api/book/getBookyId/${BookId}`);
            return responce?.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error?.responce?.data);
        }
    }
);
// delete Book
export const deleteBook = createAsyncThunk(
    'book/delete',
    async (BookId, thunkAPI) => {
        try {
            await api.delete(`/api/book/deleteBook/${BookId}`);
            return BookId;
        } catch (error) {
            return thunkAPI.rejectWithValue(error?.responce?.data);
        }
    }
);
// edit Book
export const editBook=createAsyncThunk(
    'book/update',
    async({BookId,updatedData},thunkAPI)=>{
        try {
             const responce=await api.put(`/api/Book/updateBook/${BookId}`,updatedData);
             return responce?.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error?.responce?.data);
        }
    }
)

export const bookSlice = createSlice({
    name: "book",
    initialState: {
        books: [],
        totalPages: 1,
        page: 1,
        loading: false,
        error: null,
    },
    reducers: {
        onPageChange: (state, action) => {
            state.page = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(createBook.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(createBook.fulfilled, (state, action) => {
                state.loading = false;
                state.books = action.payload;
            })
            .addCase(createBook.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            // get all Books
            .addCase(getAllBook.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getAllBook.fulfilled, (state, action) => {
                state.loading = false;
                state.books = action.payload?.books;
                state.totalPages = action.payload.pagination?.totalPages;
                state.page = action.payload.pagination?.currentPage;
            })
            .addCase(getAllBook.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            // delete Book
            .addCase(deleteBook.fulfilled, (state, action) => {
                state.loading=false;
                state.books = state.books.filter(item => item._id !== action.payload);
            })
            // get Book by id 
            .addCase(getBookById?.pending,(state)=>{
                state.loading=true;
                state.error=null;
            }) 
            .addCase(getBookById?.fulfilled,(state,action)=>{
                state.loading=false;
                state.books=action?.payload?.book;
                state.error=null;
            }) 
            .addCase(getBookById?.rejected,(state,action)=>{
                state.loading=false;
                state.error=action.payload;
            }) 
            // Update Book
            .addCase(editBook.pending,(state)=>{
                state.loading=true;
                state.error=null;
            }) 
            .addCase(editBook.fulfilled,(state,action)=>{
                state.loading=false;
                state.books=action.payload
                state.error=null;
            }) 
            .addCase(editBook.rejected,(state,action)=>{
                state.loading=false;
                state.error=action.payload;
            }) 
    },
});
export const { onPageChange } = bookSlice.actions;
export default bookSlice.reducer;
