import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import api, { ApiWithFile } from "../../Api/ApiConfig";

// create live class
export const createLiveClass=createAsyncThunk(
    "liveClass/create",
    async(credentials,{rejectWithValue})=>{
        try {
            const res=await ApiWithFile.post(`/api/liveclass/createliveClass`,credentials);
            return res.data;
        } catch (error) {
            return rejectWithValue(error?.responce?.data);
        }
    }
)
// get liveClass By id
export const getLiveClassById=createAsyncThunk(
    "liveClass/getClassById",
    async(classId,{rejectWithValue})=>{
        try {
            const res=await api.get(`/api/liveclass/getLiveCourseById/${classId}`);
            return res?.data;
        } catch (error) {
            return rejectWithValue(error?.responce?.data);  
        }
    }
)
// get All live class
export const getAllLiveClasses=createAsyncThunk(
    "liveClass/getAll",
    async(_,{rejectWithValue})=>{
        try {
            const responce=await api.get(`/api/liveclass/getAllLiveClass`);
            return responce?.data;
        } catch (error) {
            return rejectWithValue(error?.responce?.data);
        }
    }
);

// delete Live Class
export const DeleteLiveClass=createAsyncThunk(
    "liveClass/delete",
    async(liveClassId,{rejectWithValue})=>{
        try {
            await api.delete(`/api/liveclass/deleteClass/${liveClassId}`);
            return liveClassId;
        } catch (error) {
            return rejectWithValue(error?.responce?.data);
        }
    }
);
// Edit live class
export const EditLiveClass=createAsyncThunk(
    "liveClass/edit",
    async({classId,data},{rejectWithValue})=>{
        try {
            const responce=await ApiWithFile.put(`/api/liveclass/updateLiveCourse/${classId}`,data);
            return responce?.data;
        } catch (error) {
            return rejectWithValue(error?.responce?.data);
        }
    }
)
const initialState={
    loading:false,
    liveClasses:[],
    error:null,
    page:1,
    totalPages:1,
}
const liveClassSlices=createSlice({
    name:"liveClass",
    initialState,
    reducers:{},

    extraReducers:(builder)=>{
        builder
        .addCase(createLiveClass.pending,(state)=>{
            state.loading=true;
            state.error=null;
        })
        .addCase(createLiveClass.fulfilled,(state,action)=>{
            state.loading=false;
            state.liveClasses=action.payload;
        })
        .addCase(createLiveClass.rejected,(state,action)=>{
            state.loading=false;
            state.error=action.payload;
        })
        // get all live classes
        .addCase(getAllLiveClasses.pending,(state)=>{
            state.loading=true;
            state.error=null;
        })
        .addCase(getAllLiveClasses.fulfilled,(state,action)=>{
            state.loading=false;
            state.liveClasses=action.payload?.classes;
        })
        .addCase(getAllLiveClasses.rejected,(state,action)=>{
            state.loading=false;
            state.error=action.payload;
        })
        // delete live class
        .addCase(DeleteLiveClass.fulfilled, (state, action) => {
            state.loading=false;
            state.liveClasses = state.liveClasses.filter(item => item._id !== action.payload);
        })
        .addCase(getLiveClassById.pending,(state)=>{
            state.loading=false;
            state.error=null;
        })
        .addCase(getLiveClassById.fulfilled,(state,action)=>{
            state.loading=false;
            state.liveClasses=action.payload?.course;
        })
        .addCase(getLiveClassById.rejected,(state,action)=>{
            state.loading=false;
            state.error=action.payload;
        })
    }
});


export default liveClassSlices?.reducer;