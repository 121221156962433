import React from 'react'
import logo from '../../assets/logo/ex4.png'

const Loader = () => {
  return (
    <div className='absolute w-[100vw] h-[100vh] bg-blue-100 top-0 left-0 flex justify-center items-center'>
        <img src={logo} alt="Loading image" className='w-[30rem]' />
    </div>
  )
}

export default Loader