

import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

const ProtectedRoutes = ({ isAuthenticated, user }) => {
  
  const { pathname } = useLocation();
  
  // Redirect unauthenticated users to the login page
  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }

  // Prevent non-admin users from accessing admin routes
  if (isAuthenticated && user?.role !== "admin" && pathname.includes("admin")) {
    return <Navigate to="/unauth-page" />;
  }

  const restrictedPaths = ["/", "/blog", "/testseries", "/livecourse", "/currentaffairs", "/book"];
  // Redirect admin users to the admin dashboard if they're accessing the home route
  if (isAuthenticated && user?.role === "admin" && restrictedPaths.includes(pathname)) {
    return <Navigate to="/admin/dashboard" />;
  }

  // Render child routes if no redirection conditions are met
  return <Outlet />;
};

export default ProtectedRoutes;
