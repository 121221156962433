// import { checkAuth } from "./features/auth/authSlice";
// import { useEffect } from "react";
// import { useCookies } from "react-cookie";
// import Logout from "./auth/Logout";
// import { useDispatch, useSelector } from "react-redux";
// import { Route, Routes } from "react-router-dom";
// import './App.css';
// import ProtectedRoutes from "./Routing/ProtectedRoutes";
// //Dashboard
// import Dashboard from "./pages/Dashboard/Admin_Dashboard";

// import Home from "./pages/Home/Home";
// import Header from "./components/Header/Header";
// import Wishlist from "./pages/ECommerce/books/User_Wishlist";
// import Cart from "./pages/ECommerce/books/User_Cart";
// import BillingForm from "./pages/ECommerce/books/User_BillingForm";
// //live courses
// import LiveCourse from "./pages/livecourses/User_LiveCourse";
// import ExploreCourse from "./pages/livecourses/User_ExploreCourse";
// import ScheduledLiveClasses from "./pages/livecourses/User_ScheduledLiveClasses";

// import LiveClasses from "./pages/livecourses/Admin_LiveClasses";
// // import LiveHome from "./pages/livecourses/Admin_LiveHome";
// import CreateLiveClass from "./pages/livecourses/Admin_CreateLiveClass";
// import ScheduleLiveCourses from "./pages/livecourses/Admin_ScheduleLiveCourses";
// import SpeakerScreenContainer from "./pages/livecourses/speakerScreen/SpeakerScreenContainer";
// import ClassDetails from "./pages/livecourses/Admin_ClassDetails";
// import EditLiveCourse from "./pages/livecourses/Admin_EditLiveCourse";

// import Admin_Subject from "./pages/Master/Admin_Subject";
// import Category from "./pages/Master/category/Admin_Category";
// import SubCategory from "./pages/Master/subCategory/SubCategory";
// //books
// import Book from "./pages/ECommerce/books/User_Book";
// import Admin_EBooks from "./pages/ECommerce/ebooks/Admin_EBooks";
// import Admin_Books from "./pages/ECommerce/books/Admin_Books";
// import AddBook from "./pages/ECommerce/books/Admin_AddBook";
// import EditBook from "./pages/ECommerce/books/Admin_EditBook";
// import Admin_OrderReceive from "./pages/ECommerce/books/Admin_OrderReceive";
// // courses
// import Admin_Courses from "./pages/Courses/Admin_Courses";
// import Admin_CourseForm from "./pages/Courses/Admin_CourseForm";

// import UserTestSeries from "./pages/test-series/UserTestSeries";
// // import MockTest from "./Admin/Components/Contents/MockTest";
// // Blogs
// import BlogDetails from "./pages/Blog/User_BlogDetails";
// import AdminBlog from "./pages/Blog/Admin_Blog";
// import AddBlog from "./pages/Blog/Admin_AddBlog";
// import EditBlog from "./pages/Blog/Admin_EditBlog";
// import User_Blog from "./pages/Blog/User_Blog";
// //current affairs
// import AdminCurrentAffairs from './pages/currentAffairs/Admin_CurrentAffairs';
// import EditCurrentAffairs from "./pages/currentAffairs/Admin_EditCurrentAffair";
// import AddCA from "./pages/currentAffairs/Admin_AddCA";
// import User_CurrentAffairs from "./pages/currentAffairs/User_CurrentAffairs";
// import CADetails from "./pages/currentAffairs/User_CADetails";

// // import Bundles from "./Admin/Components/Contents/Bundles";
// // import Batch from "./Admin/Components/Contents/Batch";
// // import Podcasts from "./Admin/Components/Contents/Podcasts";
// import Footer from "./components/Footer/Footer";
// import { Toaster } from "react-hot-toast";
// import UnAuth from "./utils/UnAuth";
// import User_EnrolledPage from "./pages/livecourses/User_EnrolledPage";
// import UserDetailsTestSeries from "./pages/test-series/UserDetailsTestSeries";


// function App() {
//   // const isAdmin=false;
//   const { isAdmin, isAuthenticated, loading, user } = useSelector(state => state.auth);

//   const dispatch = useDispatch();
//   useEffect(() => {
//     dispatch(checkAuth());
//   }, [dispatch]);
//   return (
//     <>
//       <Toaster />
//       {isAdmin === false ? <Header /> : null}
//       <Routes>
//         {/* <Route path="" element={<ProtectedRoutes isAuthenticated={isAuthenticated} user={user}/>}/> */}

//         {/* User Unprotected Routes */}
//         {!isAdmin && (
//           <>
//             <Route path="/" element={<Home />} />
//             <Route path="/currentaffairs" element={<User_CurrentAffairs />} />
//             <Route path="/currentaffairs/:id" element={<CADetails />} />
//             <Route path="/blog" element={<User_Blog />} />
//             <Route path="/blog/:id" element={<BlogDetails />} />
//             <Route path="/book" element={<Book />} />
//             <Route path="/testseries" element={<UserTestSeries />} />
//             <Route path="/testseries/details" element={<UserDetailsTestSeries />} />
//             <Route path="/ecommerce/wishlist" element={<Wishlist />} />
//             <Route path="/ecommerce/cart" element={<Cart />} />
//             <Route path="/billingForm" element={<BillingForm />} />
//             <Route path="/livecourse" element={<LiveCourse />} />
//             <Route path="/explore/:courseId" element={<ExploreCourse />} />
//           </>
//         )}
//         {!isAdmin && isAuthenticated && (
//           <Route
//             path="/"
//             element={<ProtectedRoutes isAuthenticated={isAuthenticated} user={user} />}
//           >
//             <Route path="livecourse/:courseId" element={<ScheduledLiveClasses />} />
//             <Route path="live-class-series/:courseId" element={<User_EnrolledPage />} />
//           </Route>
//         )}
//         {/* Admin Protected Routes */}
//         {
//           isAdmin && (
//             <Route path="/" element={<ProtectedRoutes isAuthenticated={isAuthenticated} user={user} />}>
//               <Route path="admin/dashboard" element={<Dashboard />} />
//               <Route path="Master/Subject" element={<Admin_Subject />} />
//               <Route path="Master/MasterCategory/Category" element={<Category />} />
//               <Route path="Master/MasterCategory/Sub-Category" element={<SubCategory />} />
//               {/* Books */}
//               <Route path="ECommerce/EBooks" element={<Admin_EBooks />} />
//               <Route path="ECommerce/Books" element={<Admin_Books />} />
//               <Route path="ECommerce/addBook" element={<AddBook />} />
//               <Route path="ECommerce/editBook/:bookId" element={<EditBook />} />
//               <Route path="ECommerce/orderRecieve" element={<Admin_OrderReceive />} />

//               <Route path="contents/courses" element={<Admin_Courses />} />
//               <Route path="contents/CourseForm" element={<Admin_CourseForm />} />
//               {/* live class routes */}
//               <Route path="contents/liveclasses" element={<LiveClasses />} />
//               {/* <Route path="contents/liveStreaming" element={<LiveHome />} /> */}
//               <Route path="contents/createLiveClass" element={<CreateLiveClass />} />
//               <Route path="contents/liveclass/schedule/:courseId" element={<ScheduleLiveCourses />} />
//               <Route path="contents/live/:meetingId/:courseId/:classId" element={<SpeakerScreenContainer />} />
//               <Route path="contents/liveclasse/:classId" element={<ClassDetails />} />
//               <Route path="contents/update-live-course/:courseId" element={<EditLiveCourse />} />

//               {/* <Route path="contents/TestSeries" element={<TestSeries />} /> */}
//               {/* <Route path="contents/MockTest" element={<MockTest />} /> */}
//               <Route path="contents/blog" element={<AdminBlog />} />
//               <Route path="contents/add-blog" element={<AddBlog />} />
//               <Route path="contents/edit-blog/:blogId" element={<EditBlog />} />
//               <Route path="contents/current-affairs/:currentAffairId" element={<EditCurrentAffairs />} />
//               <Route path="contents/current-affairs" element={<AdminCurrentAffairs />} />
//               <Route path="contents/add-ca" element={<AddCA />} />
//               {/* <Route path="contents/Bundles" element={<Bundles />} /> */}
//               {/* <Route path="contents/Batch" element={<Batch />} /> */}
//               {/* <Route path="contents/Podcasts" element={<Podcasts />} /> */}
//               <Route path="logout" element={<Logout />} />
//               <Route path="*" element={<UnAuth />} />
//             </Route>
//           )
//         }


//       </Routes>
//       {!isAdmin && <Footer />}
//     </>
//   );
// }

// export default App;



import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'react-quill/dist/quill.snow.css';
import './App.css'
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { checkAuth } from "./features/auth/authSlice";
import ProtectedRoutes from "./Routing/ProtectedRoutes";
import InitialLoader from "./utils/loaders/InitialLoader"; // Loader Component
import ViewerScreenContainer from "./pages/livecourses/ViewerScreenContainer";
import Loader from "./utils/loaders/InitialLoading";
// import UserProfile from "./pages/profile/UserProfile";

// Lazy-loaded components
const Header = React.lazy(() => import("./components/Header/Header"));
const Footer = React.lazy(() => import("./components/Footer/Footer"));
const Logout = React.lazy(() => import("./auth/Logout"));
const UnAuth = React.lazy(() => import("./utils/UnAuth"));

// Pages (lazy-loaded)
const Home = React.lazy(() => import("./pages/Home/Home"));
const Wishlist = React.lazy(() => import("./pages/ECommerce/books/User_Wishlist"));
const Cart = React.lazy(() => import("./pages/ECommerce/books/User_Cart"));
const BillingForm = React.lazy(() => import("./pages/ECommerce/books/User_BillingForm"));
const LiveCourse = React.lazy(() => import("./pages/livecourses/User_LiveCourse"));
const ExploreCourse = React.lazy(() => import("./pages/livecourses/User_ExploreCourse"));
const ScheduledLiveClasses = React.lazy(() => import("./pages/livecourses/User_ScheduledLiveClasses"));
const User_EnrolledPage = React.lazy(() => import("./pages/livecourses/User_EnrolledPage"));
const Book = React.lazy(() => import("./pages/ECommerce/books/User_Book"));
const UserTestSeries = React.lazy(() => import("./pages/test-series/UserTestSeries"));
const UserDetailsTestSeries = React.lazy(() => import("./pages/test-series/UserDetailsTestSeries"));
const BlogDetails = React.lazy(() => import("./pages/Blog/User_BlogDetails"));
const User_Blog = React.lazy(() => import("./pages/Blog/User_Blog"));
const User_CurrentAffairs = React.lazy(() => import("./pages/currentAffairs/User_CurrentAffairs"));
const CADetails = React.lazy(() => import("./pages/currentAffairs/User_CADetails"));
const UserProfile=React.lazy(()=>import('./pages/profile/UserProfile'));

// Admin Pages (lazy-loaded)
const Dashboard = React.lazy(() => import("./pages/Dashboard/Admin_Dashboard"));
const Admin_Subject = React.lazy(() => import("./pages/Master/Admin_Subject"));
const Category = React.lazy(() => import("./pages/Master/category/Admin_Category"));
const SubCategory = React.lazy(() => import("./pages/Master/subCategory/SubCategory"));
const Admin_EBooks = React.lazy(() => import("./pages/ECommerce/ebooks/Admin_EBooks"));
const Admin_Books = React.lazy(() => import("./pages/ECommerce/books/Admin_Books"));
const AddBook = React.lazy(() => import("./pages/ECommerce/books/Admin_AddBook"));
const EditBook = React.lazy(() => import("./pages/ECommerce/books/Admin_EditBook"));
const Admin_OrderReceive = React.lazy(() => import("./pages/ECommerce/books/Admin_OrderReceive"));
const Admin_Courses = React.lazy(() => import("./pages/Courses/Admin_Courses"));
const Admin_CourseForm = React.lazy(() => import("./pages/Courses/Admin_CourseForm"));
const LiveClasses = React.lazy(() => import("./pages/livecourses/Admin_LiveClasses"));
const CreateLiveClass = React.lazy(() => import("./pages/livecourses/Admin_CreateLiveClass"));
const ScheduleLiveCourses = React.lazy(() => import("./pages/livecourses/Admin_ScheduleLiveCourses"));
const SpeakerScreenContainer = React.lazy(() => import("./pages/livecourses/speakerScreen/SpeakerScreenContainer"));
const ClassDetails = React.lazy(() => import("./pages/livecourses/Admin_ClassDetails"));
const EditLiveCourse = React.lazy(() => import("./pages/livecourses/Admin_EditLiveCourse"));
const AdminBlog = React.lazy(() => import("./pages/Blog/Admin_Blog"));
const AddBlog = React.lazy(() => import("./pages/Blog/Admin_AddBlog"));
const EditBlog = React.lazy(() => import("./pages/Blog/Admin_EditBlog"));
const AdminCurrentAffairs = React.lazy(() => import("./pages/currentAffairs/Admin_CurrentAffairs"));
const EditCurrentAffairs = React.lazy(() => import("./pages/currentAffairs/Admin_EditCurrentAffair"));
const AddCA = React.lazy(() => import("./pages/currentAffairs/Admin_AddCA"));

const App = () => {
  const dispatch = useDispatch();
  const { isAdmin,user, isAuthenticated, loading } = useSelector((state) => state.auth);
  
  const [loader,setLoader]=useState(true);

  useEffect(() => {
    dispatch(checkAuth());
  }, [dispatch]);
  useEffect(() => {
    setTimeout(() => { setLoader(false) }, 1500);
  }, []);

  if (loading || loader) return <Loader delay={1000} message="Loading app..." />;

  return (
    <>
      <Toaster />
      <React.Suspense fallback={<InitialLoader delay={500} message="Loading component..." />}>
        {isAdmin === false && <Header />}
        <Routes>
          {/* User Unprotected Routes */}
          {!isAdmin && (
            <>
              <Route path="/" element={<Home />} />
              <Route path="/currentaffairs" element={<User_CurrentAffairs />} />
              <Route path="/currentaffairs/:id" element={<CADetails />} />
              <Route path="/blog" element={<User_Blog />} />
              <Route path="/blog/:id" element={<BlogDetails />} />
              <Route path="/book" element={<Book />} />
              <Route path="/testseries" element={<UserTestSeries />} />
              <Route path="/testseries/details" element={<UserDetailsTestSeries />} />
              <Route path="/ecommerce/wishlist" element={<Wishlist />} />
              <Route path="/ecommerce/cart" element={<Cart />} />
              <Route path="/billingForm" element={<BillingForm />} />
              <Route path="/livecourse" element={<LiveCourse />} />
              <Route path="/explore/:courseId" element={<ExploreCourse />} />
            </>
          )}
          {!isAdmin && isAuthenticated && (
            <Route element={<ProtectedRoutes isAuthenticated={isAuthenticated} user={user} />}>
              <Route path="livecourse/:courseId" element={<ScheduledLiveClasses />} />
              <Route path="live-class-series/:courseId" element={<User_EnrolledPage />} />
              <Route path="/profile" element={<UserProfile />} />
              <Route path="/live/:meetingId/:scheduledClassId" element={<ViewerScreenContainer />} />
            </Route>
          )}
          {/* Admin Protected Routes */}
          {isAdmin && (
            <Route path="/" element={<ProtectedRoutes isAuthenticated={isAuthenticated} user={user} />}>
              <Route path="admin/dashboard" element={<Dashboard />} />
              <Route path="Master/Subject" element={<Admin_Subject />} />
              <Route path="Master/MasterCategory/Category" element={<Category />} />
              <Route path="Master/MasterCategory/Sub-Category" element={<SubCategory />} />
              <Route path="ECommerce/EBooks" element={<Admin_EBooks />} />
              <Route path="ECommerce/Books" element={<Admin_Books />} />
              <Route path="ECommerce/addBook" element={<AddBook />} />
              <Route path="ECommerce/editBook/:bookId" element={<EditBook />} />
              <Route path="ECommerce/orderRecieve" element={<Admin_OrderReceive />} />
              <Route path="contents/courses" element={<Admin_Courses />} />
              <Route path="contents/CourseForm" element={<Admin_CourseForm />} />
              <Route path="contents/liveclasses" element={<LiveClasses />} />
              <Route path="contents/createLiveClass" element={<CreateLiveClass />} />
              <Route path="contents/liveclass/schedule/:courseId" element={<ScheduleLiveCourses />} />
              <Route path="contents/live/:meetingId/:courseId/:classId" element={<SpeakerScreenContainer />} />
              <Route path="contents/liveclasse/:classId" element={<ClassDetails />} />
              <Route path="contents/update-live-course/:courseId" element={<EditLiveCourse />} />
              <Route path="contents/blog" element={<AdminBlog />} />
              <Route path="contents/add-blog" element={<AddBlog />} />
              <Route path="contents/edit-blog/:blogId" element={<EditBlog />} />
              <Route path="contents/current-affairs" element={<AdminCurrentAffairs />} />
              <Route path="contents/current-affairs/:currentAffairId" element={<EditCurrentAffairs />} />
              <Route path="contents/add-ca" element={<AddCA />} />
              <Route path="logout" element={<Logout />} />
              <Route path="*" element={<UnAuth />} />
            </Route>
          )}
        </Routes>
        {!isAdmin && <Footer />}
      </React.Suspense>
    </>
  );
};

export default App;
