import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import api, { ApiWithFile } from "../../Api/ApiConfig";

// create live class
export const scheduleLiveClass=createAsyncThunk(
    "scheduleLiveClass/create",
    async(credentials,{rejectWithValue})=>{
        try {
            const res=await api.post(`/api/liveclass/scheduleLiveCourse`,credentials);
            return res.data;
        } catch (error) {
            return rejectWithValue(error?.responce?.data);
        }
    }
)
// get liveClass By id
export const getSchedulesLiveClassById=createAsyncThunk(
    "scheduleLiveClass/getClassById",
    async(classId,{rejectWithValue})=>{
        try {
            const res=await api.get(`/api/liveclass/getLiveCourseById/${classId}`);
            return res?.data;
        } catch (error) {
            return rejectWithValue(error?.responce?.data);  
        }
    }
)
// get All live class
export const getAllSchedulesLiveClasses=createAsyncThunk(
    "scheduleLiveClass/getAll",
    async(courseId,{rejectWithValue})=>{
        try {
            const responce=await api.get(`/api/liveclass/getAllScheduledCourseByCourseId/${courseId}`);
            return responce?.data;
        } catch (error) {
            return rejectWithValue(error?.responce?.data);
        }
    }
);

// delete Live Class
export const DeleteSchedulesLiveClass=createAsyncThunk(
    "scheduleLiveClass/delete",
    async(liveClassId,{rejectWithValue})=>{
        try {
            await api.delete(`/api/liveclass/deleteClass/${liveClassId}`);
            return liveClassId;
        } catch (error) {
            return rejectWithValue(error?.responce?.data);
        }
    }
);
// Edit live class
export const EditSchedulesLiveClass=createAsyncThunk(
    "scheduleLiveClass/edit",
    async({classId,data},{rejectWithValue})=>{
        try {
            const responce=await ApiWithFile.put(`/api/liveclass/updateLiveCourse/${classId}`,data);
            return responce?.data;
        } catch (error) {
            return rejectWithValue(error?.responce?.data);
        }
    }
);


const initialState={
    loading:false,
    schedulesLiveClasses:[],
    error:null,
    page:1,
    totalPages:1,
}
const scheduleLiveClassSlices=createSlice({
    name:"scheduleLiveClass",
    initialState,
    reducers:{},

    extraReducers:(builder)=>{
        builder
        .addCase(scheduleLiveClass.pending,(state)=>{
            state.loading=true;
            state.error=null;
        })
        .addCase(scheduleLiveClass.fulfilled,(state,action)=>{
            state.loading=false;
            state.schedulesLiveClasses=action.payload;
        })
        .addCase(scheduleLiveClass.rejected,(state,action)=>{
            state.loading=false;
            state.error=action.payload;
        })
        // get all live classes
        .addCase(getAllSchedulesLiveClasses.pending,(state)=>{
            state.loading=true;
            state.error=null;
        })
        .addCase(getAllSchedulesLiveClasses.fulfilled,(state,action)=>{
            state.loading=false;
            state.schedulesLiveClasses=action.payload?.courses;
        })
        .addCase(getAllSchedulesLiveClasses.rejected,(state,action)=>{
            state.loading=false;
            state.error=action.payload;
        })
        // delete live class
        .addCase(DeleteSchedulesLiveClass.fulfilled, (state, action) => {
            state.loading=false;
            state.schedulesLiveClasses = state.schedulesLiveClasses.filter(item => item._id !== action.payload);
        })
        .addCase(getSchedulesLiveClassById.pending,(state)=>{
            state.loading=false;
            state.error=null;
        })
        .addCase(getSchedulesLiveClassById.fulfilled,(state,action)=>{
            state.loading=false;
            state.schedulesLiveClasses=action.payload?.course;
        })
        .addCase(getSchedulesLiveClassById.rejected,(state,action)=>{
            state.loading=false;
            state.error=action.payload;
        })
    }
});


export default scheduleLiveClassSlices?.reducer;